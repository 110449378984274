<template>
  <v-card @click="goToDetails" style="width: 100%">
    <v-card-text class="d-flex flex-row mt-3" style="width: 100%">
      <div>
        <v-img
          :src="require('@/assets/svg/awards.svg')"
          width="30"
          height="30"
          contain
        />
      </div>
      <div class="d-flex flex-column mx-3" style="flex-grow:1">
        <span style="font-size: 16px; font-family: 'Poppins-SemiBold';">
          {{ award.title }}
        </span>
        <!-- <div
          style="font-size: 12px;font-family: 'Poppins-Regular';"
          class="d-flex"
        >
          <div
            class="url-button"
            @click.stop="goToAgencyProfile(award.sponsor)"
            @mousedown.stop
            @touchstart.stop
          >
            {{ award.sponsor_name }}
          </div>
        </div> -->
        <span
          style="color: #0a5b8a; font-size: 12px;font-family: 'Poppins-Regular';"
        >
          {{ formatDate(award.offer_date) }}
        </span>
        <span> Goal: {{ award.goal }} hrs </span>
        <div>
          Items:
          <span
            style="font-size: 12px;font-family: 'Poppins-Regular';"
            v-for="item in award.items"
            :key="item.name"
          >
            {{ item.name }}{{ item.unit ? `(${item.unit}),` : "" }}
          </span>
        </div>
        <div v-if="award.sponsor_name">
          Sponsor:
          <span
            :class="{ 'url-button': award.sponsor_type != 'Admin' }"
            @click.stop="
              goToAgencyProfile(
                award.sponsor_type == 'Admin' ? '' : award.sponsor
              )
            "
            @mousedown.stop
            @touchstart.stop
            >{{ award.sponsor_name }}</span
          >
        </div>
      </div>
      <div style="flex-shrink: 0" v-if="!hideButtons">
        <v-tooltip bottom v-if="profile._id == award.sponsor">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #0a5b8a; border-style: solid; border-radius: 36px;"
              small
              @click.stop="onEdit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#0a5b8a" style="margin-top: 2px;" size="18"
                >mdi-pencil</v-icon
              >
            </v-btn>
          </template>
          Edit Award
        </v-tooltip>
        <v-tooltip bottom v-if="profile._id == award.sponsor">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #0a5b8a; border-style: solid; border-radius: 36px;"
              small
              class="ml-2"
              @click.stop="onDelete"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#0a5b8a" style="margin-top: 2px;" size="18"
                >mdi-delete</v-icon
              >
            </v-btn>
          </template>
          Delete Award
        </v-tooltip>
      </div>
    </v-card-text>
    <v-card-text class="mt-0 pt-0">
      <div class="position-relative">
        <span
          class="text-block"
          v-if="text_collapsed"
          v-linkified
          v-text="collapsed_text"
        />
        <span
          v-else
          v-text="award.description"
          class="text-block"
          v-linkified
        />
        <span
          class="url-button"
          @click.stop="text_collapsed = false"
          @mousedown.stop
          @touchstart.stop
          v-if="text_collapsed"
          x-small
          style="position: absolute; right: 18px; bottom: 0; background: white"
        >
          ...see more
        </span>
      </div>
      <div
        v-if="users.length > 0 && profileType != 'Volunteer'"
        class="mt-2 app-bold-font"
      >
        Eligible users
        <div v-for="user in users" :key="user._id" class="my-4">
          <editable-avatar
            :image="user.thumbnail ? user.thumbnail : user.image"
            :editable="false"
            :size="30"
            class="ml-2"
          />
          <span
            class="ml-2"
            style="font-family: 'Poppins-SemiBold'; color: #242f36"
          >
            {{ user.first_name }} {{ user.last_name }} -
            {{ formatMinutes(user.volunteered_hours) }} hrs
          </span>
        </div>
      </div>
      <div
        v-else-if="users.length == 0 && profileType != 'Volunteer'"
        class="mt-5"
      >
        No eligible volunteers
      </div>
      <div v-if="profileType == 'Volunteer'" class="mt-2">
        <span style="font-family: 'Poppins-SemiBold';">My hours:</span>
        {{ formatMinutes(myHours) }} hrs
      </div>
      <div
        v-if="
          myHours >= parseInt(award.goal) * 60 && profileType == 'Volunteer'
        "
        style="font-family: 'Poppins-SemiBold';"
      >
        Eligible
      </div>
      <div
        v-else-if="profileType == 'Volunteer'"
        style="font-family: 'Poppins-SemiBold';"
      >
        Not eligible
      </div>
    </v-card-text>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
    <edit-awards
      :dialog="editDialog"
      :onClose="onCloseDialog"
      :item="award"
      v-if="editDialog"
    />
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseDialog"
      :onConfirm="onDeleteAward"
    />
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
import EditAwards from "./EditAwards.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import EditableAvatar from "../../components/EditableAvatar.vue";
export default {
  components: { EditAwards, ConfirmDeleteDialog, EditableAvatar },
  props: {
    award: Object,
    hideButtons: {
      type: Boolean,
      default: false,
    },
    onClicked: Function,
    eligibles: Array,
  },
  data() {
    return {
      snackbar: false,
      snackMessage: null,
      text_collapsed: false,
      collapsed_text: "",
      editDialog: false,
      deleteDialog: false,
      users: [],
      myHours: 0,
    };
  },
  methods: {
    ...mapActions("awards", {
      deleteAward: "deleteAward",
      getVolunteerHours: "getVolunteerHours",
      getEligibles: "getEligibles",
    }),
    goToDetails() {
      // if (!this.onClicked) {
      //   this.$router.push({
      //     name: "agency-need-details",
      //     query: {
      //       _id: this.award._id,
      //     },
      //   });
      // } else {
      //   this.onClicked(this.award._id);
      // }
    },
    goToAgencyProfile(agency) {
      if (agency)
        this.$router.push({
          name: "agency-profile-details",
          params: { id: agency },
        });
    },
    getCollapedText() {
      if (!this.award.description) {
        this.text_collapsed = false;
        return;
      }
      const full_text = this.award.description.trim();
      const textArray = full_text.split(/\r\n|\r|\n/);

      if (textArray.length > 3) {
        this.collapsed_text = textArray.slice(0, 3).join("\n");
        this.text_collapsed = true;
        return;
      }

      // Check for the number of characters
      if (full_text.length > 270) {
        this.collapsed_text = full_text.substring(0, 270);
        this.text_collapsed = true;
        return;
      }

      // If neither condition is met, set collapsed_text to full_text
      this.collapsed_text = full_text;
      this.text_collapsed = false;
    },
    formatDate(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy hh:MM TT"
      );
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    onEdit() {
      this.editDialog = true;
    },
    onDelete() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
      this.editDialog = false;
    },
    onDeleteAward() {
      this.deleteAward({ _id: this.award._id }).catch((error) => {
        console.log(error);
      });
    },
    formatMinutes(value) {
      var hrs = Math.floor(value / 60);
      var str = `${hrs}:`;
      // if (hrs > 9) str += `${hrs}:`;
      // else str += `0${hrs}:`;
      var mins = Math.floor(value % 60);
      if (mins > 9) str += `${mins}`;
      else str += `0${mins}`;
      return str;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type",
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
  },
  watch: {
    eligibles(newValue) {
      this.users = newValue.filter(
        (e) => e.volunteered_hours >= parseFloat(`${this.award.goal}`) * 60
      );
    },
  },
  mounted() {
    this.getCollapedText();
    if (
      this.profileType == "Agency" ||
      (this.award.sponsor_type == "Admin" && this.profileType == "Admin")
    ) {
      this.users = this.eligibles.filter(
        (e) => e.volunteered_hours >= parseFloat(`${this.award.goal}`) * 60
      );
    } else if (
      this.profileType == "Admin" &&
      this.award.sponsor_type == "Agency"
    ) {
      this.getEligibles({ _id: this.award.sponser })
        .then((res) => {
          this.users = res.filter(
            (e) => e.volunteered_hours >= parseFloat(`${this.award.goal}`) * 60
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (this.profileType == "Volunteer") {
      this.getVolunteerHours({
        sponsor: this.award.sponsor,
        sponsor_type: this.award.sponsor_type,
      })
        .then((res) => {
          if (res.length != 0) {
            this.myHours = res[0].minutes;
          }
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
  },
};
</script>
