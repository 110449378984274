<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title class="d-flex flex-row justify-between app-bold-font">
        Edit Award
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <div style="min-height: 500px; max-height: 61vh; overflow: auto">
        <v-card-text class="app-regular-font">
          Edit the awards details your volunteer can get.
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Title
          </div>
          <v-text-field
            label="title"
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            v-model="award.title"
            :rules="[rules.required]"
            dense
          />
        </v-card-text>
        <v-card-text
          class="d-flex py-0"
          :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
        >
          <div>
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Goal hours
            </div>
            <div
              :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'"
            >
              <v-text-field
                label="Ex: 120"
                solo
                flat
                outlined
                dense
                class="mt-2 app-regular-font"
                v-model="award.goal"
                type="number"
                :rules="[rules.required]"
              />
            </div>
          </div>
          <div :class="$vuetify.breakpoint.xs ? 'w-100' : 'ml-3'">
            <div class="app-medium-font dark-font-color">
              Date
            </div>
            <div
              class="d-flex mt-2"
              :class="$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'"
            >
              <div
                :style="
                  $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'
                "
              >
                <v-menu
                  v-model="date_picker"
                  :close-on-content-click="false"
                  :return-value.sync="awardDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :top="$vuetify.breakpoint.xs"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="award.date"
                      label="MM/DD/YYYY"
                      solo
                      flat
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      autocomplete="off"
                      :style="
                        $vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'
                      "
                    />
                  </template>
                  <v-date-picker v-model="awardDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date_picker = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="onSaveDate(awardDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div :style="$vuetify.breakpoint.xs ? 'width: 100%' : ''">
                <vue-timepicker
                  format="hh:mm a"
                  v-model="awardTime"
                  :minute-interval="5"
                  :class="$vuetify.breakpoint.xs ? 'w-100 mb-5' : 'ml-3'"
                />
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
            Items
          </div>
          <div class="d-flex mt-2">
            <div
              :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'"
            >
              <v-text-field
                label="Name"
                solo
                flat
                outlined
                v-model="newName"
                style="font-family:'Poppins-Regular';"
                dense
              />
            </div>
            <div
              :style="$vuetify.breakpoint.xs ? 'width: 100%' : 'width: 200px;'"
              class="ml-3"
            >
              <v-text-field
                label="Unit"
                solo
                flat
                outlined
                v-model="newUnit"
                style="font-family:'Poppins-Regular';"
                dense
                type="number"
              />
            </div>
            <div>
              <v-btn
                @click="onAddItem"
                v-text="'Add Item'"
                color="#b5e539"
                class="white--text ml-3"
                :disabled="!newName"
              />
            </div>
          </div>
          <div v-if="award.items" class="mb-3">
            <div v-for="item in award.items" :key="item.name" class="ml-3">
              {{ item.name }}{{ item.unit ? " --- " + item.unit : "" }}
              <v-btn icon @click="onDeleteItem(item)" class="ml-5"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-center flex-column py-0">
          <div class="app-medium-font dark-font-color">
            Description
          </div>
          <v-textarea
            rows="4"
            auto-grow
            label="Description"
            solo
            flat
            outlined
            class="mt-2 app-regular-font"
            v-model="award.description"
            :rules="[rules.required]"
          />
        </v-card-text>
        <v-card-text v-if="message">
          <v-alert dense type="error" v-text="message" v-if="message" />
        </v-card-text>
      </div>
      <v-card-actions class="pb-6 mt-3 d-flex flex-row justify-center">
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  components: { VueTimepicker },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: Object,
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      message: null,
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      award: {},
      date_picker: false,
      awardDate: null,
      awardTime: null,
      newName: null,
      newUnit: null,
    };
  },
  methods: {
    ...mapActions("awards", {
      editAward: "editAward",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (!this.award.title) {
        this.message = "Please add title.";
        return;
      }
      if (!this.award.goal) {
        this.message = "Please add goal hours.";
        return;
      }
      if (
        (!this.award.items || this.award.items.length == 0) &&
        !this.award.description
      ) {
        this.message = "Please add award items or description.";
        return;
      }
      var params = {
        ...this.award,
      };
      if (this.award.date && this.awardTime) {
        params.offer_date = `${params.date} ${this.awardTime.hh}:${this.awardTime.mm} ${this.awardTime.a}`;
      }
      this.loading = true;
      this.editAward(params)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    },
    changeDateFormat(dateStr) {
      return `${dateStr.substring(5, 7)}/${dateStr.substring(
        8,
        10
      )}/${dateStr.substring(0, 4)}`;
    },
    onSaveDate(dateStr) {
      this.award.date = this.changeDateFormat(dateStr);
      this.date_picker = false;
    },
    onAddItem() {
      if (!this.award.items) {
        this.award.items = [];
      }
      this.award.items = this.award.items.filter((a) => a.name != this.newName);
      this.award.items.push({ name: this.newName, unit: this.newUnit });
      this.newName = "";
      this.newUnit = "";
    },
    onDeleteItem(item) {
      this.award.items = this.award.items.filter((a) => a.name != item.name);
      this.award = { ...this.award };
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
  },
  mounted() {
    this.award = { ...this.item };
    var convertedDate = this.convertTZ(this.award.offer_date, this.timezone);
    this.award.date = dateFormat(convertedDate, "mm/dd/yyyy"); // hh:MM TT
    this.awardTime = {
      hh: dateFormat(convertedDate, "hh"),
      mm: dateFormat(convertedDate, "MM"),
      a: dateFormat(convertedDate, "tt"),
    };
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
    overflow-y: hidden;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.time-picker {
  width: 107px;
  border-color: #d094ea !important;
  border-width: 1px;
  height: 40px;
}
.time-picker :deep(input.display-time) {
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker :deep(input.display-time:hover) {
  border-color: currentColor;
}
.time-picker :deep(input.display-time:focus-visible) {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker :deep(.clear-btn) {
  padding: 5px 16px;
  margin-top: 5px;
}
</style>
